.add-price-container {
  position: inherit;
  z-index: 1;
  background-color: white;
}

.add-price-container-desktop {
  padding-bottom: 13vw;
  padding-top: 3vw;
}
.add-price-container-desktop .add-price-wrapper-desktop .store-section-desktop {
  /* margin: 0 auto; */
  margin-left: 0;
}

.add-price-container-desktop
  .add-price-wrapper-desktop
  .store-section-desktop
  a {
  max-width: 40% !important;
}

.add-price-container-mobile {
  padding-top: 10vw;
  padding-bottom: 15vw;

}