.global-loader-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 999999;
  position: absolute;
}

.global-loader-wrapper-desktop {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-loader-wrapper-mobile {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border-radius: 50%;
  position: relative;
  animation: rotate 2s linear infinite;
}

.loader-mobile {
  width: 50vw;
  height: 50vw;
}

.loader-desktop {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  position: relative;
  animation: rotate 2s linear infinite;
}

.loader-mobile::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid var(--primaryColor);
  animation: prixClipFix 2s linear infinite;
}

.loader-desktop::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--primaryColor);
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.global-loader-image-container-mobile {
  position: absolute;
  text-align: center;
}

.global-loader-image-container {
  position: absolute;
  text-align: center;
}

.global-loader-image-container-mobile img {
  max-width: 60%;
}
.global-loader-image-container-desktop img {
  max-width: 100%;
}
