.menuFooter {
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: space-around;
  background-image: url("../../../../assets/menu-line.png");
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
}

.menu-footer-container-mobile {
  width: 70%;
  display: flex;
  z-index: 9999;
  margin-left: -5vw;
}

.social-icon-Mobile {
  display: flex;
  width: 100%;
  height: 15vw;
  margin-top: 4vw;
}

.menu-fb {
  width: 100%;
  height: 50%;
  background-image: url("../../../../assets/menu-fb.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.menu-insta {
  width: 100%;
  height: 50%;
  background-image: url("../../../../assets/menu-insta.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.menu-phone {
  width: 100%;
  height: 50%;
  background-image: url("../../../../assets/menu-phone.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.menu-mail {
  width: 100%;
  height: 50%;
  background-image: url("../../../../assets/menu-mail.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.country-picker-mobile-container {
  /* margin-left: -10vw; */
  display: flex;
  justify-content: center;
}

.appsList a {
  color: rgb(70, 69, 69);
  line-height: 1.4;
}


 div:nth-child(1) > div.menuDropDown > div.menuItems > div > div:nth-child(1) > a {
  line-height: 1.6;
}