.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f525;
}

.form {
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
}

.form-desktop {
    width: 35%;
    padding: 3vw;
    border-radius: 3vw;
}

.form-mobile {
    width: 80%;
    padding: 7vw;
    border-radius: 6vw;
}

.form-title {
    text-align: left;
    color: #333333;
}

.form-title-desktop {
    font: normal normal 800 2.3vw/2.6vw Montserrat;
    margin-bottom: 1vw;
    margin-top: 2vw;
    margin-left: 1.5vw;
}

.form-title-mobile {
    font: normal normal 800 6.8vw/7.6vw Montserrat;
    margin-top: 6vw;
    margin-bottom: 3vw;
    margin-left: 4vw;

}

.form-description {
    color: #666666;
    text-align: left;
}

.form-description-desktop {
    font: normal normal 300 0.93vw/1.5vw Comfortaa;
    margin-bottom: 1.8vw;
    margin-left: 1.5vw;
}

.form-description-mobile {
    font: normal normal 300 3.7vw/5.8vw Comfortaa;
    margin-bottom: 6vw;
    margin-left: 4vw;
}

.form-button {
    background-color: #a8d1fc;
    color: white;
    border: none;
    width: 100%;

}



.form-button-desktop {
    padding: 1vw;
    border-radius: 1.5vw;
    font: normal normal bold 1.1vw/1.6vw Comfortaa;
    margin-top: 2vw;
    cursor: pointer;
}

.active-button {
    background-color: #00A0E3
}

.form-button-mobile {
    margin-top: 10vw;
    font: normal normal bold 3.7vw/5.2vw Comfortaa;
    border-radius: 5vw;
    padding: 3.5vw 0;
    margin-bottom: 6vw;
}

.form-input {
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f7f7f7;
    color: #666666;
    border: 0;
}

.form-input-mobile {
    font: normal normal 300 3.7vw/5.2vw Comfortaa;
    border-radius: 5vw;
    padding: 3.5vw 0;
    padding-left: 4vw;
}

.form-input-desktop {
    font: normal normal 300 0.93vw Comfortaa;
    border-radius: 1.5vw;
    padding: 1vw 0;
    padding-left: 1.5vw;
    line-height: 1.3vw;
    appearance: none;
}

.contacts-message-success-desktop {
    color: #71945f;
    font-weight: bold;
    font-size: 0.8vw;
    margin-top: 1vw;
}

.contacts-message-success-mobile {
    color: #71945f;
    font-weight: bold;
    font-size: 3.3vw;
}

.form-error-desktop {
    color: red;
    font-weight: bold;
    font-size: 0.8vw;
    margin-bottom: 1vw;
}

.form-error-mobile {
    color: red;
    font-weight: bold;
    font-size: 3.3vw;
}

.form-select {
    appearance: none;
    background-image: url('../../assets/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    position: relative;

}

.form-select-desktop {
    background-size: 1.2vw
}

.form-select-mobile {
    padding-left: 3vw;
    appearance: none;
    background-size: 2.2vw
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.dropdown-header {
    font: normal normal 300 0.93vw Comfortaa;
    border-radius: 1.5vw;
    padding: 1vw 0;
    padding-left: 1.5vw;
    line-height: 1.3vw;
    background-color: #f5f5f5;
    text-align: left;
    color: #666666;

}

.dropdown-header-mobile {
    font: normal normal 300 3.7vw/5.2vw Comfortaa;
    border-radius: 5vw;
    padding: 3.5vw 0;
    padding-left: 3vw;
    padding-left: 4vw;
    cursor: none;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    /* border: 1px solid #ccc; */
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    text-align: left;
    border-radius: 1vw;
    padding: 1vw;
    list-style-type: none;
    font: normal normal 300 0.93vw Comfortaa;
    margin-top: 1vw;
}

.dropdown-list-mobile {
    font: normal normal 300 3.7vw/5.2vw Comfortaa;
    border-radius: 5vw;
    padding-left: 4vw;
    cursor: none;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
    margin-bottom: 0.1vw;
}

.dropdown-item-desktop:hover {
    background-color: #f0f0f0;
}