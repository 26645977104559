.App {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.privacy-policy-main-desktop {
  flex: 1;
  display: flex;
}

.privacy-policy-main-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* background-color: #f8faff; */
}

.privacy-policy-main-container {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  position: relative;
}
