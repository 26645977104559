.add-price-wrapper {
  margin: 0 auto;
  display: flex;
}
.add-price-wrapper-desktop {
  width: 67%;
  height: 17.2vw;
  margin-top: -2.4vw;
  margin-bottom: 10vw;
}
.add-price-wrapper-mobile {
  flex-direction: column;
}

.addPrice-left {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #b171b3;
  border-radius: 1vw
}


.addPrice-left-mobile {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6vw;
  padding-bottom: 7vw;
}
.addPrice-left-weaning-mobile {
  background-color: #aed181;
}
.add-price-left-content {
  margin-left: -2vw;
  margin-top: 2vw;
}
.addPrice-left-content {
  text-transform: uppercase;
}

.addPrice-left-content-mobile {
  margin-top: 10vw;
}

.addPrice-left-content p {
  color: white;
  font: normal normal 300 0.95vw/1.5vw Comfortaa;
  text-align: center;
}
.addPrice-left-content-mobile p {
  color: white;
  font: normal normal 300 3.7vw/10.5vw Comfortaa;
  text-align: center;
  margin-top: -5vw;
}
.add-price-left-storeSection-desktop {
  margin-top: 1vw;
  margin-left: 2vw;
  z-index: 999;
}
.add-price-left-storeSection-mobile {
  margin-top: 2.5vw;
  transform: scale(1.2);
}
.add-price-left-InstallNow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3vw;
}
.addPrice-left-installNow-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1vw;
  width: 80%;
  /* margin-left: -10vw; */
}
.addPrice-left-installNow-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5vw;
  width: 80%;
  margin-bottom: 5vw;
}
.white-arrow {
  max-width: 100%;
}

.add-price-right-functionality-desktop p {
  color: white;
  text-align: center;
  font: normal normal 300 0.95vw/1.4vw Comfortaa;
}
.add-price-right-functionality-mobile p {
  color: white;
  text-align: center;
  font: normal normal 300 3.8vw/5.5vw Comfortaa;
}
.addPrice-rightn-big-price-desktop {
  color: white;
  font: normal normal 4vw/1.6vw Montserrat;
  margin-bottom: 1.5vw;
  text-align: center;
  margin-left: 2vw;
}
.addPrice-rightn-big-price-mobile {
  color: white;
  font: normal normal 300 5vw/5.6vw Comfortaa;
}

.addPrice-rightn-bigPrice-mobile {
  color: white;
  font: normal normal bold 23vw/6vw Montserrat;
}
.addPrice-rightn-big-price-lastP {
  color: #ffffff;
  text-transform: uppercase;
}
.addPrice-rightn-big-price-lastP-desktop {
  font: normal normal 300 0.95vw/1.3vw Comfortaa;
  letter-spacing: 0px;
  margin-top: 0.9vw;
}
.addPrice-rightn-big-price-lastP-mobile {
  text-align: center;
  font: normal normal 300 3.7vw/4.8vw Comfortaa;
  letter-spacing: 0px;
  margin-top: 5vw;
}

.install-now-container-desktop {
  margin-left: 2vw;
}
.install-now-container-mobile {

  width: 80%;
}
.install-now-container img {
  width: 100%;
  height: auto;
}

.install-now-arrow-container-desktop {
  position: absolute;
  margin-top: -8vw;
  width: 80%;
  right: -5%;
}

.install-now-arrow-container-mobile {
  margin-top: -20vw;
  width: 22%;
  position: absolute;
  right: 7vw;
}


.add-price-section-install-now-text-desktop {
  margin-top: 2vw;
  transform: rotate(-5deg);
  color: #face4d;
  font-size: 4.2vw;
  font-family: MyFontPHCond;
  text-transform: uppercase;
  position: relative;
  background: #face4d;
  background-clip: text;
  z-index: 4;
  text-align: left;
  letter-spacing: 0.05vw;
}

.add-price-section-install-now-text-desktop::after {
  color: var(--childDev-color);
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 1.2vw #fff;
  left: 0;
  z-index: -3;
}

.add-price-section-install-now-text-mobile {
  transform: rotate(-3deg);
  margin-top: 5vw;
  color: #face4d;
  font-size: 14.2vw;
  font-family: MyFontPHCond;
  text-transform: uppercase;
  position: relative;
  background: #face4d;
  background-clip: text;
  z-index: 4;
  text-align: center;
  letter-spacing: 0.05vw;
  line-height: 1;
}

.add-price-section-install-now-text-mobile::after {
  color: var(--childDev-color);
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 5vw #fff;
  left: 0;
  top: 0vw;
  z-index: -3;
}
