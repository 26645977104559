.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container-header-desktop {
  background-color: #b171b3;
  padding: 1.5rem 1.5rem;
  border-radius: 17px;
}

.container-header-mobile {
  background-color: #f7f7f7;
  padding: 1.4rem 0;
  border-radius: 11px;
}

.contaier-header-image-container {
  display: flex;
  align-items: center;
}

.contaier-header-image-container-desktop {
  gap: 2vw;
  flex: 3 55%;
}

.contaier-header-image-container-mobile {
  flex: 1 48%;
  /* margin-left: 0.5vw; */
}

.btn {
  background: transparent;
  border-color: transparent;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.btn-desktop {
  /* cursor: pointer; */
  margin-right: 2.7rem;
  align-self: center;
  min-width: 2rem;
}

.btn-mobile {
  justify-content: center;
  margin-right: 3vw;
  margin-left: 2vw;
}

.btn-movie-player-mobile {
  background: transparent;
  border-color: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container-header-title {
  color: #ffffff;
  text-transform: uppercase;
}

.container-header-title-desktop {
  font: normal bold 1.15vw Comfortaa;
  letter-spacing: 0.3px;
  margin-left: -2.8vw;
}

.container-header-title-mobile {
  font: normal normal bold 2.55vw/3.2vw Comfortaa;
  text-align: left;
  line-height: 1.5;
  margin-left: -2vw;
  color: #666666;
}

.container-header-text-container-desktop {
  color: white;
  flex: 2;
}

.container-header-text-container-mobile {
  display: flex;
  color: var(--primaryColor);
  flex: 2 5%;
}

.container-header-last-text-desktop {
  flex: 2 1%;
  color: white;
}

.container-header-last-text-mobile {
  display: flex;
  flex: 2;
  color: #EBB146;
}

.container-header-text-container p,
.container-header-last-text p {
  font: normal normal bold 1vw/2.1vw Comfortaa;
  text-transform: uppercase;

}

.container-header-text-container-mobile p,
.container-header-last-text-mobile p {
  font: normal normal bold 2.55vw / 3.2vw Comfortaa;
  text-transform: uppercase;
}

.container-header h4 {
  color: #fff;
  text-transform: uppercase;
  font: normal normal 1.1vw Comfortaa;
  margin-left: 1vw;
}

.movie-player-desktop {
  transition: 0.2s ease-in;
  width: 2.2vw;
  height: 2.2vw;
  margin-left: 0.6vw;
  margin-top: 0.15vw;
}

.movie-player-mobile {
  width: 7vw;
  height: auto
}

.movie-player-open-desktop {
  transition: 0.2s ease-in;
  width: 1.7vw;
  height: 1.7vw;
  transform: rotate(90deg);
  margin-right: 2vw;
}

.movie-player-open-mobile {
  transition: 0.2s ease-in;
  width: 5vw;
  height: 5vw;
  transform: rotate(90deg);
}
