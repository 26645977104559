.aboutUs-single-content {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.aboutUs-single-content-desktop {
  flex-direction: row;
  gap: 6vw;
  justify-content: space-around;
  margin-bottom: 3vw;

}

.aboutUs-single-content-mobile {
  flex-direction: column;
  gap: 6vw;
  padding-bottom: 10vw;
  margin-top: 8vw;
}

.aboutUs-single-content-desktop:nth-child(even) {
  flex-direction: row-reverse;
}

.aboutUs-single-content-context-desktop {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.aboutUs-single-content-title-desktop {
  font: normal normal normal 5vw MyFontPHCond;
  text-transform: uppercase;
}

.aboutUs-single-content-title-mobile {
  font: normal normal normal 15.5vw/6.5vw MyFontPHCond;
  margin-bottom: 8vw;
  z-index: 9999;
  position: relative;
}

.aboutUs-single-content-title-1 {
  color: #cca3cd;
  margin-top: 8vw;
}

.aboutUs-single-content-title-2 {
  color: #ffc983;
}

.aboutUs-single-content-title-3 {
  color: #a3c6cc;
}

.aboutUs-single-content-info-desktop {
  color: #666666;
  font: normal normal 300 0.96vw/1.79 Comfortaa;
}

.aboutUs-single-content-info-mobile {
    color: #666666;
    font: normal normal 300 3.3vw/1.9 Comfortaa;
  }

  .aboutUs-single-content-info-mobile  p {
    margin-bottom: 4vw;
  }

  .aboutUs-single-content-info-desktop  p {
    margin-bottom: 1vw;
  }

.single-content-image-container-desktop {
  width: 50%;
}

.single-content-image-container img {
  width: 105%;
  height: auto;
}
