.terms-wrapper {
  background-color: white;
}

.terms-wrapper-desktop .global-loader-wrapper-desktop {
  position: relative;
  /* height: 70vh; */
  margin-top: -5vw;
  padding-bottom: 10vw;
}

.terms-wrapper-mobile .global-loader-image-container-mobile {
  right: auto;
}

.terms-wrapper-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.terms-title-container-desktop {
  margin: 0 auto;
}

.terms-header-title-desktop {
  color: #00a0e3;
  font: normal normal normal 6.55vw/7.92vw MyFontPHCond;
  text-transform: uppercase;
  position: relative;
}

.terms-title-container-desktop p {
  margin-top: -2vw;
}

.terms-header-title-mobile {
  color: #00a0e3;
  font: normal normal normal 11.55vw/7.92vw MyFontPHCond;
  text-transform: uppercase;
  margin-top: -3vw;
  position: relative;
}

.terms-image-container-desktop {
  width: 104%;
  margin-top: -15vw;
  margin-left: -2vw;
}

.terms-image-container-mobile {
  width: 110%;
  margin-top: -18vw;
  margin-left: -5vw;
}

.terms-image-container img {
  width: 100%;
  height: auto;
  z-index: -10;
}

.terms-artwork-desktop {
  /* background-color: ; */

  width: 30%;
  margin-left: 15vw;
}

.terms-artwork-mobile {
  width: 60%;
  margin-left: 20vw;
}

.terms-artwork img {
  width: 100%;
  height: auto;
}

.terms-content {
  background-color: white;
  margin: 0 auto;
  margin-top: -11.5vw;
  padding-bottom: 10vw;
}
.terms-content-desktop {
  padding-top: 10vw;
  padding-bottom: 10vw;
}

.terms-content-mobile {
  padding-top: 8vw;
}

.terms-content p {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  color: #666666;
  font: normal normal normal 0.84vw/1.42vw Comfortaa;
}

.terms-content-desktop p {
  margin-bottom: 0.7vw;
}

.terms-content-desktop p strong {
  display: block;
  margin-bottom: 1.4vw;
}

.terms-content-mobile p {
  font: normal normal 300 3.3vw/5.5vw Comfortaa;
}

.terms-content-info-desktop {
  top: 2vw;
  position: relative;
  font: normal normal normal 1.3vw Comfortaa;
  color: #666666;
}

.terms-content-info-mobile {
  top: 4vw;
  position: relative;
  font: normal normal normal 3.8vw Comfortaa;
  color: #666666;
}

.terms-content-mobile p {
  margin-bottom: 2.5vw;
}

.terms-content-mobile p strong {
  display: block;
  margin-bottom: 5vw;
}

.terms-wrapper-desktop .error-fairy-container {
  margin-top: 18vw;
}

.terms-wrapper-mobile .error-fairy-container {
  margin-top: 40vw;
}
