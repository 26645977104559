.App {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.pregnancy-main-desktop {
  flex: 1;
  display: flex;
  background-color: #faf7f4;
}

.pregnancy-main-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #faf7f4;
}

.pregnancy-main-container {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  position: relative;
}
.pregnancy-main .add-price-wrapper {
  margin-bottom: 0vw;
}

#addPriceSection
  > div.addPrice-right.addPrice-right-desktop.addPrice-right-pregnancy.addPrice-right-pregnancy-desktop {
  padding-top: 4vw;
}
