.accordion-section-container {
  background-color: white;
}

.accordion-section-container-desktop {
  margin-top: -2vw;
}

.accordions-title {
  color: #707070;
}

.accordions-title-desktop {
  font: normal normal normal 4.8vw MyFontPHCond;
  margin-top: 0vw;
  text-transform: uppercase;
}

.accordions-title-mobile {
  font: normal normal normal 11.5vw MyFontPHCond;
  margin-bottom: 2vw;
  letter-spacing: 0.1vw;
}

.accordions-content-desktop {
  width: 40%;
  margin: 0 auto;
  color: #707070;
  text-align: center;
  font: normal normal 300 1vw/1.4vw Comfortaa;
}

.accordions-content-mobile {
  font: normal normal 300 3.3vw Comfortaa;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 4vw;
  color: #707070;
  line-height: 1.7;
}

.accordion-section-container-info-text {
  color: #ebb146;
}

.accordion-section-container-info-text-mobile {
  width: 60%;
  margin: 0 auto;
  font: normal normal bold 3.3vw/5.9vw Comfortaa;
  margin-top: -3vw;
  padding-bottom: 6.5vw;
}

.accordion-section-container-info-text-desktop {
  font: normal bold 0.94vw/1.72vw Comfortaa;
  margin-top: -3vw;
  padding-bottom: 1.5vw;
}

.carousel-img-crown-desktop {
  max-width: 35%;
  height: auto;
}