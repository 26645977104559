.last-blogs-post-wrapper {
  background-color: white;
  /* margin-top: -18.5vw; */
  z-index: 99;
  position: inherit;
}
.section-container-desktop {
  width: 90%;
  margin: 0 auto;
}

.section-container-mobile {
  margin-top: -0.5vw;
}

.section-container-blog-articles {
  color: #666;
  text-transform: uppercase;
}

.section-container-blog-articles-desktop h2 {
  text-align: center;
  margin-top: -5vw;
  font: normal normal normal 4.4vw MyFontPHCond;
  letter-spacing: 0.3px;
}

.section-container-blog-articles-mobile h2 {
  padding-top: 10vw;
  font: normal normal normal 12vw MyFontPHCond;
}

.section-container-blog-articles span {
  color: #b171b3;
}

.section-container-subTitle {
  color: #707070;
}

.section-container-subTitle-desktop h3 {
  margin-bottom: 4vw;
  font: normal normal 300 1vw Comfortaa;
  letter-spacing: 0.14px;
}

.section-container-subTitle-mobile h3 {
  margin: 0 auto;
  width: 60%;
  font: normal normal 300 3.3vw Comfortaa;
  margin-bottom: 5vw;
  line-height: 1.5;
}

.blog-container-content {
  margin: 0 auto;
  display: flex;
}

.blog-container-content-desktop {
  flex: row;
  gap: 1.5vw;
  margin-top: -2vw;
  width: 95%;
  margin: 0 auto;
}

.blog-container-content-mobile {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-container-item {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.blog-container-item-desktop {
  width: 33%;
  z-index: 99;
}

.blog-container-item-mobile {
  width: 90%;
}

.blog-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.blog-icons-container-desktop {
  margin-top: 0.2vw;
  gap: 0.7vw;
}
.blog-icons-container-mobile {
  width: 30%;
  margin: 0 auto;
  display: flex;
  gap: 3vw;
  margin-bottom: 7vw;
}

.lastBlogSection-icon-container {
  margin-top: 0.2vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.7vw;
}

.lastBlogSection-icon-container-Mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3vw;
  margin-bottom: 7vw;
}

.blog-icons-container-mobile img {
  filter: invert(44%) sepia(0%) saturate(27%) hue-rotate(244deg) brightness(87%)
    contrast(88%);
}

.last-blogs-error-desktop {
  padding-bottom: 5vw;
}

.last-blogs-error {
  background-color: white;
}

.last-blogs-error-desktop .error-fairy-container {
  margin-top: 2vw;
}

.last-blogs-error-mobile .error-fairy-container-mobile {
  margin-top: 26vw;
}

.pregnancy-main-container > div.last-blogs-post-wrapper.last-blogs-post-wrapper-desktop {
  padding-bottom: 4vw;
}