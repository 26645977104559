.pregnancy-new-content-wrapper-mobile {
  margin-top: -10vw;
}

.pregnancy-new-content-wrapper-desktop {
  margin-top: -24vw;
}

.pregnancy-new-content-container {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.pregnancy-new-content-container-mobile {
  margin-top: -7vw;
  padding-top: 15vw;
}

.pregnancy-new-content-container-desktop {
  padding-bottom: 10vw;
  margin-top: 3vw;
}

.pregnancy-new-content-image-container img {
  width: 110%;
  height: auto;
  margin-left: -4vw;
}

.pregnancy-new-single-container {
  display: flex;
}

.pregnancy-new-single-container-desktop {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 2vw;
  margin-top: -5vw;
}

.pregnancy-new-single-container-mobile {
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 7vw;
}

.pregnancy-new-single-container-desktop:nth-child(even) {
  flex-direction: row-reverse;
}

.pregnancy-new-single-container-content {
  display: flex;
}

.pregnancy-new-single-container-content-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  /* margin-left: 10vw; */
}

.pregnancy-new-single-container-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 18vw;
}

.pregnancy-new-container-title-desktop {
  font: normal normal normal 3.9vw MyFontPHCond;
  margin-bottom: 1vw;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.pregnancy-new-container-title-mobile {
  font: normal normal normal 11.5vw/11.5vw MyFontPHCond;
  margin-bottom: 4vw;
}

.pregnancy-new-title-0,
.pregnancy-new-title-4 {
  color: #946e95;
}

.pregnancy-new-title-1,
.pregnancy-new-title-5 {
  color: #b4a9ee;
}

.pregnancy-new-title-2,
.pregnancy-new-title-7 {
  color: #9097cb;
}

.pregnancy-new-title-3,
.pregnancy-new-title-6 {
  color: #cca3cd;
}

.pregnancy-new-container-content-desktop {
  color: #707070;
  font: normal normal 300 0.8vw/1.7vw Comfortaa;
  text-align: left;
}

.pregnancy-new-container-content-mobile {
  color: #707070;
  font: normal normal 300 3.3vw/5.5vw Comfortaa;
  text-align: center;
}

.pregnancy-new-container-image-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.pregnancy-new-container-image-mobile {
  margin: 0 auto;
  width: 80%;
  margin-bottom: 7vw;
}

.pregnancy-new-container-image img {
  max-width: 90%;
  height: auto;
}

.pregnancy-new-video-wrapper-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 7%;
}

.pregnancy-new-video-wrapper-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: -5vw;
  margin-bottom: 8vw;
}

.new-container-phone-image-mobile {
  width: 60%;
  position: absolute;
  top: 8vw;
  left: 7vw;
}

.new-container-phone-image-desktop {
  width: 90%;
  position: absolute;
  top: 5vw;
  left: 2vw;
}

.pregnancy-new-video-wrapper-video-mobile {
  max-width: 100%;
  height: auto;
}

.pregnancy-new-video-wrapper-video-desktop {
  position: relative;
  top: 6.95vw;
  right: -0.3vw;
  max-width: 130%;
  height: auto;
}

.pregnancy-new-purple-arrow-mobile {
  margin: 0 auto;
  width: 10%;
  position: relative;
  top: 18vw;
}

.pregnancy-new-purple-arrow-desktop {
  margin: 0 auto;
  width: 3%;
  position: relative;
  top: 8vw;
}

.pregnancy-new-purple-arrow img {
  max-width: 100%;
}

.pregnancy-new-purple-arrow-desktop img:hover {
  cursor: pointer;
}
