main {
  width: 100%;
  display: flex;
  align-items: center;
}
.container-desktop {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 4vw;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  gap: 1rem 2rem;
  margin-top: -2vw;
}

.container-mobile {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10vw;
}

.accordion-icon {
  width: 1.85vw;
  height: 100%;
}

.accordion-content__small {
  width: 90%;
}

.accordion-content__big {
  width: 90%;
}

.info-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.info-container {
  width: 100%;
}

.showInfo h5 {
  text-align: left;
  color: white;
  font-size: 2.9vw;
}

.showInfo p {
  text-align: left;
  color: white;
  padding-bottom: 2vw;
  font-size: 2.6vw;
}

.info-container p {
  margin-bottom: 7vw;
}

.accordion-icon-Mmobile {
  transition: all 0.3s;
}

.accordion-line-end-desktop {
  border-bottom: 1px solid #f3f3f3;
  margin-top: 1vw;
}

.accordion-line-end-mobile {
  border-bottom: 1px solid #f3f3f3;
  margin-top: 2.5vw;
}

.accordion-x {
  display: flex;
  margin-left: 0.5vw;
}

.accordion-checked {
  display: flex;
  margin-left: 0.5vw;
}

.info-desktop {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  flex: 1 100%;
  /* flex:2 100% */
  /* align-items: stretch; */
  /* max-width: 1142px; */
}

.info-mobile {
  display: flex;
  flex-direction: column;
  /* flex: 1 100%; */
  align-items: stretch;
}
