.header-install-now-container-desktop {
  text-align: left;
  transform: rotate(-7deg);
  margin-top: 0.3vw;
}

.header-install-now-container-mobile {
  margin-top: 8vw;
  text-align: center;
  transform: rotate(-7deg);
  z-index: 200;
  position: relative;
}

.animate-install-now {
  animation: rotating 1200ms ease-in-out infinite;
}

@keyframes rotating {
  0% {
    -webkit-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  10% {
    -webkit-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  20% {
    -webkit-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  30% {
    -webkit-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  40% {
    -webkit-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  60% {
    -webkit-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }
}

.header-install-now-title-desktop,
.header-install-now-title-price-desktop {
  font: normal normal normal 3.95vw/3.95vw MyFontPHCond;
  color: #face4d;
  text-align: left;
  position: relative;
  background-clip: text;
  z-index: 4;
  margin-left: 2vw;
  text-transform: uppercase;
}

.header-install-now-title-mobile {
  color: #face4d;
  text-align: center;
  margin: 0 auto;
  font-size: 12vw;
  text-transform: uppercase;
  font-family: MyFontPHCond;
  position: absolute;
  top: 5.5vw;
  left: 10vw;
  z-index: 4;
}

.header-install-now-title-price-mobile {
  color: #face4d;
  text-align: center;
  margin: 0 auto;
  font-size: 17vw;
  text-transform: uppercase;
  font-family: MyFontPHCond;
  line-height: 14vw;
  z-index: 4;
  margin-top: 2vw;
}

.header-install-now-title-price-mobile::after {
  color: #face4d;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 4.1vw #fff;
  left: 0;
  z-index: -3;
  top: 0;
  margin-top: 2vw;

}

.header-install-now-title-mobile::after {
  color: #face4d;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 3.1vw #fff;
  left: 0;
  z-index: -3;
  top: 0;
}

.header-install-now-title-desktop::after,
.header-install-now-title-price-desktop::after {
  color: #face4d;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 1vw #fff;
  left: 0;
  z-index: -3;
  top: 0;
}

.pregnancy-header-title-color-mobile {
  margin: 0 auto;
  font: normal normal normal 10.7vw MyFontPHCond;
  color: #b171b3;
  line-height: 1;
  text-align: center;
}

.pregnancy-header-title-color-mobile::after {
  color: #b171b3;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 2.88vw #fff;
  left: 0;
  z-index: -3;
  top: 0;
}
