.aboutUs-content-wrapper-desktop {
  background-color: white;
  margin-top: -11vw;
  padding-top: 7vw;
  position: relative;
  padding-bottom: 5vw;
}

.aboutUs-content-wrapper-mobile {
  background-color: white;
  margin-top: -15vw;
  padding-top: 10vw;
  padding-bottom: 5vw;
}
