.pregnancy-header-image-conainer-desktop {
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position-x: center;
  background-position-y: -1vw;
  padding-bottom: 1vw;
}

.pregnancy-header-image-conainer-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  position: relative;
  background-position-x: center;
  background-position-y: -7vw;
  height: 95vh;
}

.pregnancy-header-image-container-bg-desktop {
  background: url("../assets/web.webp");
  background-size: cover;
  background-position-x: center;
  background-position-y: -1vw;
  padding-bottom: 1vw;
}
.pregnancy-header-image-container-en-desktop {
  background: url("../assets/web2-EN.webp");
  background-size: cover;
  background-position-x: center;
  background-position-y: -1vw;
  padding-bottom: 1vw;
}
.pregnancy-header-image-container-de-desktop {
  background: url("../assets/web2-DE.webp");
  background-size: cover;
  background-position-x: center;
  background-position-y: -1vw;
  padding-bottom: 1vw;
}

.pregnancy-header-image-container-bg-mobile {
  background: url("../assets/pregnancy-header-mobile2.jpg") no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: -7vw;
  height: 95vh;
}
.pregnancy-header-image-container-en-mobile {
  background: url("../assets/pregnancy-header-mobileEN.png") no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: -7vw;
  height: 95vh;
}
.pregnancy-header-image-container-de-mobile {
  background: url("../assets/pregnancy-header-mobileDE.png") no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: -7vw;
  height: 95vh;
}

.pregnancy-header-content {
  display: flex;
  flex-direction: column;
}

.pregnancy-header-content {
  color: white;
}

.pregnancy-header-content-desktop p {
  font: normal normal 300 1.25vw Comfortaa;
  line-height: 2;
  margin-left: 2vw;
  text-align: left;
}

.pregnancy-header-content-mobile p {
  font: normal normal 300 3.3vw Comfortaa;
  line-height: 1.8;
  margin-bottom: 1vw;
  text-transform: uppercase;
}
.pregnancy-header-content-mobile p:last-of-type {
  margin-bottom: 0vw;
}

.pregnancy-header-title-desktop p {
  font: normal normal normal 4.68vw MyFontPHCond;
  color: #333333;
  letter-spacing: 0.1px;
  text-align: left;
  line-height: 1;
  margin-left: 14vw;
}

.pregnancy-header-title-mobile p {
  margin: 0 auto;
  width: 60%;
  font: normal normal normal 9.7vw MyFontPHCond;
  color: #666666;
  line-height: 1.1;
}

.pregnancy-header-title-mobile p:first-child {
  margin: 0 auto;
  width: 60%;
  font: normal normal normal 9.7vw MyFontPHCond;
  color: #666666;
  margin-top: 18vw;
  line-height: 1.1;
}
.pregnancy-header-title-color {
  font: normal normal normal 4.95vw MyFontPHCond;
  color: #b171b3;
  text-align: left;
  position: relative;
  background-clip: text;
  z-index: 4;
  margin-left: 2vw;
  padding-bottom: 0.5vw;
}

.pregnancy-header-title-color::after {
  color: #b171b3;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 0.9vw #fff;
  left: 0;
  z-index: -3;
  top: 0;
}

.pregnancy-header-title-color-mobile {
  margin: 0 auto;
  font: normal normal normal 12.7vw MyFontPHCond;
  color: #b171b3;
  line-height: 1;
  text-align: center;
}

.pregnancy-header-title-color-mobile::after {
  color: #b171b3;
  content: attr(data-text);
  position: absolute;
  -webkit-text-stroke: 2.88vw #fff;
  left: 0;
  z-index: -3;
  top: 0;
}

.pregnancy-header-learn-more {
  color: #27aee7;
  text-transform: uppercase;
}

.pregnancy-header-learn-more-desktop {
  margin-top: 1vw;
  text-align: left;
  font-size: 1.1vw;
}

.pregnancy-header-learn-more-desktop p {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

.pregnancy-header-learn-more-mobile {
  font-size: 3.2vw;
  position: relative;
  top: 1vw;
  text-decoration: underline;
  z-index: 99;
}

.pregnancy-header-learn-more-mobile p {
  display: inline-block;
  text-decoration: underline;
}

.pregnancy-header-learn-more-mobile:-webkit-any-link {
  cursor: none;
}

.pregnancy-header-store-container-desktop {
  margin-left: 4vw;
  margin-top: 4vw;
  /* width: 35%; */
}
.pregnancy-header-store-container-mobile {
  z-index: 10;
  position: absolute;
  bottom: 2%;
}

.pregnancy-header-install-now-arrow-desktop {
  display: flex;
  margin-bottom: 3vw;
  margin-left: -1vw;
}

.pregnancy-header-install-now-arrow-mobile {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  width: 100%;

}

.pregnancy-header-install-now-container-desktop {
  margin-top: -0.5vw;
}

.pregnancy-header-install-now-container-mobile {
  margin: 0 auto;
  width: 70%;
  position: relative;
  top: 25vw;
}

.pregnancy-header-install-now {
  width: 100%;
  height: auto;
  transform: rotate(-4deg);
}

.pregnancy-header-arrow-container-desktop {
  margin-top: -5vw;
  margin-left: -4vw;
  width: 10%;
}

.pregnancy-header-arrow-container-mobile {
  display: flex;
  justify-content: flex-end;
  top: 10%;
}

.pregnancy-header-arrow-desktop {
  max-width: 100%;
  height: auto;
  /* transform: rotate(-30deg); */
}

.pregnancy-header-arrow-mobile {
  height: auto;
  transform: rotate(10deg);
  width: 27%; /* Set the width of the image */
  margin-left: 3vw;
  position: absolute;
  margin-top: -10vw;
}

.pregnancy-header-arrows-desktop {
  margin: 0 auto;
  width: 6%;
  position: relative;
  top: -1vw;
  left: 24vw;
}

.pregnancy-header-arrows-desktop img {
  width: 100%;
  height: auto;
}

.pregnancy-header-arrows-mobile {
  margin: 0 auto;
  width: 8%;
  position: relative;
  top: 13vw;
}

.pregnancy-header-arrows-mobile img {
  width: 100%;
  height: auto;
}

.header-arrows img {
  width: 100%;
  height: auto;
}

.pregnancy-header-clouds-desktop {
  height: 5vw;
  width: 110%;
  background-color: white;
}

.pregnancy-header-clouds-desktop img {
  margin-left: -2vw;
  width: 100%;
  margin-top: 3vw;
}

.pregnancy-header-clouds-mobile {
  height: 0vw;
  width: 110%;
  /* background-color: white; */
}

#section01
  > div.container-section-wrapper.container-section-wrapper-mobile
  > div.container-left.container-left-mobile
  > div {
  margin-right: -0.5vw;
}

.new-header {
  text-transform: uppercase;
  line-height: 1;
}

.new-header-desktop {
  width: 48%;
  margin-bottom: 1vw;
}

.new-header-mobile {
  width: 80%;
}

.pregnancy-subtitle {
  color: 666;
  font: normal normal 2.62vw/2.62vw StudioScript;
  text-align: left;
  margin-left: 2vw;
  padding-bottom: 1vw;
}

.pregnancy-subtitle-mobile {
  color: 666;
  font: normal normal 6.62vw/6.62vw StudioScript;
  text-align: center;
  margin-top: 2vw;
}

.pregnancy-wrapper-desktop {
  width: 75%;
  margin: 0 auto;
  margin-top: 9vw;
}

.pregnancy-wrapper-mobile {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* position: absolute; */
  /* top: 13%; */
  text-align: center;
  /* margin-top: 25vw; */
  height: 95vh;
  gap: 10vh;
}

.pregnancy-header-arrow-global-desktop {
  margin-left: 3vw;
  transform: rotate(25deg);
}

.pregnancy-info-container-mobile {
  margin-top: -8vh;
}

.international-install-for-free-mobile {
  margin-left: 10vw;
}
