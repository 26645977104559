.App {
  display: flex;
  text-align: center;
  width: 100%;
}

.faq-main-desktop {
  flex: 1;
  display: flex;
}

.faq-main-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.faq-main-container {
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  position: relative;
}

/* #scrollContainer > div:nth-child(2) > div.carousel-page.carousel-page-desktop {
  margin-top: 0;
} */
