.store-section-desktop {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  margin-bottom: 2.5vw;
  margin-left: -2vw;
}

.store-section-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 5vw;
  margin-top: 1vw;
  gap: 2vw;
  z-index: 0;
}

.store-section a {
  z-index: 9999;
}

.store-section-mobile a {
  width: 30%;
  height: auto;
}

.store-img {
  max-width: 100%;
  height: auto;
}

.store-section-desktop a {
  max-width: 11%
}