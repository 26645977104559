.social-media-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  margin-bottom: 4vw;
}

.social-media-container {
  display: flex;
  width: 2.5vw;
  min-width: 25px;
  height: 2.5vw;
  min-height: 25px;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/ellipse.svg");
  transition: 0.1s linear;
}

.social-media-container:hover,
.social-media-container:focus {
  transform: scale(1.1);
  background-image: url("../../../../assets/Ellipse-purple.svg");
}

.social-media-container:active .instagramIcon,
.social-media-container:hover .instagramIcon,
.social-media-container:active .facebookIcon,
.social-media-container:hover .facebookIcon {
  filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(149deg)
    brightness(103%) contrast(105);
}
