.footer-component {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.footer-component-desktop {
  margin-top: -8vw;
}


.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(to top, #fff 60%, transparent 40%);
}

.footer-container-desktop {
  padding-top: 24vw;
  margin-top: -32vw;
  padding-bottom: 4vw;
}

.footer-container-mobile {
  background-color: white;
  padding-top: 20vw;
  margin-top: -30vw;
}

.footer-content-desktop {
  display: flex;
  justify-content: space-around;
  width: 60%;
  height: 100%;
  z-index: 9;
}

.footer-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 60%;
  margin-top: 2vw;
}

.adsContainerMobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.footerColumn {
  display: flex;
  flex-direction: column;
  height: 80%;
}
.footerColumnMobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 7vw;
}
.footer-title-desktop {
  font: normal normal normal 2.1vw MyFontPHCond;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: #b171b3;
  white-space: pre;
  opacity: 1;
  margin-bottom: 0.6vw;
}

.footer-title-mobile {
  font: normal normal normal 10vw MyFontPHCond;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  text-align: center;
  color: #b171b3;
  white-space: pre;
  opacity: 0.8;
  margin-top: 5vw;
}
.footer-title-2 {
  color: #cca3cd;
}

.footer-title-3 {
  color: #b39ddb;
}

.footer-links-desktop,
.footer-links-bold-desktop {
  display: flex;
  justify-content: center;
  width: auto;
  text-decoration: blink;
  text-align: center;
  font-family: "Comfortaa";
  letter-spacing: 0.1px;
  font-size: 0.7vw;
  margin-bottom: 1.2vw;
  white-space: pre;
  text-transform: uppercase;
  opacity: 0.8;
  text-decoration: none;
  color: #707070;
}

.footer-links a,
.footer-links-bold a {
  color: #707070;
  display: inline-block;
}

.footer-links-bold a {
  font-weight: bold;
}

.footer-links-mobile {
  letter-spacing: 0.2px;
  white-space: pre;
  color: #707070;
  opacity: 0.8;
}

.footer-links-mobile a,
.footer-links-bold-mobile a {
  font: normal bold 3.3vw/3.4vw Comfortaa;
  line-height: 8.4vw;
  letter-spacing: 0.1vw;
}

.footer-links-bold-mobile a {
  font-weight: bold;
}

.footer-links-mobile:-webkit-any-link {
  cursor: none;
}

.credits {
  background-color: #f8fcfe;
}

.credits-desktop {
  padding: 1vw 0;
}

.credits-desktop a {
  font-family: "Comfortaa";
  margin-top: 10px;
  letter-spacing: 0px;
  color: #00a0e3;
  font-size: 0.5vw;
}

.credits-desktop p {
  font: normal normal 300 0.65vw/0.2vw Comfortaa;
  text-align: center;
}

.credits-mobile {
  font: normal normal 300 2.95vw/5.2vw Comfortaa;
  letter-spacing: 0px;
  opacity: 1;
  color: #707070;
  padding-top: 3vw;
}

.credits-mobile a {
  color: #00a0e3;
  font: normal normal 300 2.9vw/5.3vw Comfortaa;
  text-transform: lowercase;
  width: auto;
  margin-bottom: 5vw;
  display: inline-block;
  text-decoration: none;
  margin-left: 2vw;
}

.credits-mobile a:-webkit-any-link {
  cursor: none;
}

.footer-clauds-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-clouds-desktop {
  width: 100%;
  height: auto;
  z-index: 9;
}

.footer-clouds-mobile {
  width: 150%;
  margin-top: -10vw;
}

.single-footer-container-desktop {
  width: 30%;
}

.active-footer-link {
  font-weight: bold;
}
.active-footer-link a:hover {
  cursor:default
}