.slider-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.slider-wrapper-desktop {
  margin-left: 2vw;
  margin-top: 3vw;
}

.slider-wrapper-mobile {
  margin: 0 auto;
}

.image-container {
  width: 50%;
}

.image-rotate-desktop {
  width: 110%;
  transform: rotate(177deg);
  margin-left: 3.6vw;
  margin-top: 5.5vw;
}

.image-rotate-mobile {
  width: 120%;
  margin-top: 18vw;
  margin-left: 1.5vw;
}

.image-container-normal-desktop {
  width: 110%;
  margin-left: -9vw;
  margin-top: -2vw;
}

.image-container-normal-mobile {
  width: 130%;
  margin-left: -10vw;
  margin-top: -10vw;
}

.slider-container {
  height: 100%;
  width: 100%;
  position: relative;
  margin: auto;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.section-desktop {
  width: 80%;
  height: 100%;
  margin-top: 1vw;
  margin-left: 2.8vw;
}

.section-mobile {
  max-width: 100%;
  height: auto;
  /* margin-top: 2.3vw; */
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.slides-desktop {
  width: 81%;
  position: relative;
  left: 1vw;
}

.slides-mobile {
  width: 86%;
  position: relative;
  left: 1vw;
  top: -1.5vw;
}

.slider-img-desktop {
  width: 80%;
  height: 100%;
}

.slider-img-mobile {
  height: auto;
  width: 100%;
  position: relative;
  margin-top: 4vw;
}

.slider-img-international-mobile {
  width: 97%;
}

.slider-video {
  width: 80%;
  height: auto;
}

.slider-video-mobile {
  width: 100%;
  margin-top: 4vw;
}

.btn-player {
  border: none;
  background: none;
  position: absolute;
  transition: 0.2s ease-in-out;
}

.play-btn {
  background: url("../../assets/movie-player-play-button.svg") no-repeat;
  margin: 0 auto;
  opacity: 0;
}

.play-btn-desktop {
  width: 4vw;
  height: 4vw;
  z-index: 999;
  margin: 0 auto;
  top: 50%;
  left: 43.5%;
}

.play-btn-mobile {
  width: 12vw;
  height: 12vw;
  z-index: 999;
  top: 43%;
  left: 33%;
}

.pause-btn {
  background: url("../../assets/pause.svg") no-repeat;
  opacity: 0;
}

.pause-btn-desktop {
  width: 4vw;
  height: 4vw;
  z-index: 999;
  margin: 0 auto;
  top: 50%;
  left: 43.5%;
}

.pause-btn-mobile {
  width: 12vw;
  height: 12vw;
  z-index: 999;
  margin: 0 auto;
  top: 43%;
  left: 33%;
}

.play-btn:active,
.play-btn:hover,
.pause-btn:active,
.pause-btn:hover,
.play-btn-mobil:active,
.play-btn-mobile:hover,
.pause-btn-mobile:active,
.pause-btn-mobile:hover {
  opacity: 1;
  cursor: pointer;
}

.video-wrapper {
  width: 100%;
  height: auto;
}

.section-phone-img-desktop {
  position: absolute;
  width: 140%;
  z-index: 999;
  /* max-width: 100%; */
  left: -5.3vw;
  right: 0;
}

.section-phone-img-mobile {
  width: 225%;
  position: absolute;
  z-index: 99;
  margin-left: -4vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
