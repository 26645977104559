.pregnancy-header-counter {
  background-color: #9097cb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: lighter;
  gap: 1.6vw;
}

.pregnancy-header-counter-desktop {
  height: 5.5vw;
  margin-top: -1vw;
  position: relative;
}

.pregnancy-header-counter-mobile {
  flex-direction: column;
  height: 18.6vw;
  font: normal normal 0.8vw Comfortaa;
  margin-top: -7vw;
  position: relative;
  z-index: 0;
}

.pregnancy-header-counter-text-desktop {
  font: normal bold 3.4vw Montserrat;
}

.pregnancy-header-counter-text-mobile {
  font-family: Montserrat;
  font-size: 11vw;
  margin-top: -1vw;
  font-weight: bold;
}

.pregnancy-header-counter-install-desktop {
  font: normal normal 300 1.36vw Comfortaa;
  text-transform: uppercase;
  margin-top: 0.4vw;
}

.pregnancy-header-counter-install-mobile {
  font-family: Comfortaa;
  text-transform: uppercase;
  font-size: 2.7vw;
  font-weight: 300;
}
