@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"),
    url(./assets/Comfortaa-VariableFont_wght.ttf) format("truetype");
}

body {
  height: 100%;
  margin: 0;
  font-family: Comfortaa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}
#root {
  height: 100%;
  --primaryColor: #b171b3;
  --childDev-color: #a5d3e8;
}
*:focus {
  outline: none 0px;
}
* {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  /* user-select: none; */
}
* ::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera*/
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "MyFontPHCond";
  src: local("MyFontPHCond"),
    url(./assets//PH\ 600\ Cond.otf) format("opentype");
}

@font-face {
  font-family: "StudioScript";
  src: local("StudioScript"), url("./assets/studioscriptctt.ttf");
}

.react-datepicker {
  z-index: 1;
}

a {
  text-decoration: none;
  color: white;
}